import axios from 'axios';
import React, { useEffect, useRef,  useState } from 'react';
import { Bounce } from 'react-reveal';
import { Segment , Icon, Input, Button, Loader,  Form, Menu, Tab, TextArea, Comment, Dropdown} from 'semantic-ui-react';
import GConf from '../../../AssetsM/generalConf';
import SKLT from '../../../AssetsM/Cards/usedSlk';
import NG from './notifGenre';
import { toast } from 'react-toastify';
import { useTranslation, Trans } from 'react-i18next';
import ShowMoreText from "react-show-more-text";
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'

const SendBox = ({SendMessage, setMesgC,msgContent}) =>{
    const { t, i18n } = useTranslation();
    return(<>
             <div className='row '>
                <div className='col-10 col-lg-11 align-self-center'>
                <Form>
                    <TextArea placeholder={t('communUsed.forumPage.inoutPlch')} value={msgContent} className="mb-2 border-div" rows='2' onChange={ (e) => setMesgC(e.target.value)}></TextArea>
                </Form>
                </div>
                <div className='col-2 col-lg-1 align-self-center text-center'><Button  icon='send'  className='rounded-circle mb-2' onClick={SendMessage}></Button></div>
            </div>
        </>)
}

const CustomTabs = ({activeIndex, setActiveIndex}) => {
    const { t, i18n } = useTranslation();
     
    return(<>

           <div className="mt-1 p-1 mb-4"   style={{width:'100%', overflowX: 'auto', overflowY : 'hidden', whiteSpace:'nowrap'}}> 
                <Menu secondary >
                    <Menu.Item key={0} active={activeIndex == 0} className='rounded-pill' onClick={ () => setActiveIndex(0)}>
                        <span style={{color: '#848a94'}}>
                            <b>
                            <span className={`bi bi-blockquote-left`}></span> Text
                            </b>
                        </span>
                    </Menu.Item>
                    
                    <Menu.Item key={2} active={activeIndex == 2} className='rounded-pill' onClick={ () => setActiveIndex(2)}>
                        <span style={{color: '#216e55'}}>
                            <b>
                            <span className={`bi bi-images`}></span> Images
                            </b>
                        </span>
                    </Menu.Item>
                   {/* <Menu.Item key={3} active={activeIndex == 3} className='rounded-pill' onClick={ () => setActiveIndex(3)}>
                        <span style={{color: '#b32525'}}>
                            <b>
                            <span className={`bi bi-camera-reels`}></span> {t('appPages.publicationPage.tabsData.video')}
                            </b>
                        </span>
                    </Menu.Item>
                     <Menu.Item key={5} active={activeIndex == 5} className='rounded-pill' onClick={ () => setActiveIndex(5)}>
                        <span style={{color: 'gray'}}>
                            <b>
                            <span className={`bi bi-badge-ad`}></span> Annonces
                            </b>
                        </span>
                    </Menu.Item>
                    <Menu.Item key={4} active={activeIndex == 4} className='rounded-pill' onClick={ () => setActiveIndex(4)}>
                        <span style={{color: 'pink'}}>
                            <b>
                            <span className={`bi bi-trash`}></span> Supprimer
                            </b>
                        </span>
                    </Menu.Item> */}
                    
                </Menu>
          </div>
    </>)
}

const TextPubCard = ({publicationData, setPublicationData, SavePublicationFunc, disabledSaveBtn , OnKeyPressFunc, loaderState}) => {
    const { t, i18n } = useTranslation();
     
    return(<>
            <div className='  card-body  mb-4 border-div'>
                <Form className='mb-3 '>
                    <TextArea placeholder='Ajouter Text' onKeyPress={event => OnKeyPressFunc(event)} maxLength={300} className='border-0 font-droid'  rows={5} value={publicationData.text} onChange={ (e,value) => setPublicationData({...publicationData, text:e.target.value})} />
                </Form>
                <div className='p-1'>
                    <Button className='rounded-pill'  fluid onClick={() => SavePublicationFunc('text','text','text')} disabled={disabledSaveBtn} size='small' icon style={{backgroundColor:'#848a94', color:'white'}} >   {t('appPages.publicationPage.communSaveBtn')}   <Icon name='save' />   <Loader inverted active={loaderState} inline size='tiny' className='ms-2 text-danger'/></Button>
                </div>
            </div>
    </>)
}
const ArticlePubCard = ({publicationData, setPublicationData, SavePublicationFunc, disabledSaveBtn , OnKeyPressFunc, loaderState}) => {
    return(<>
            <div className='  card-body  mb-4 border-div'>
                <Form className='mb-3'>
                    <TextArea placeholder='La taille maximale est de 3500 caractères.' onKeyPress={event => OnKeyPressFunc(event)} maxLength={3500} className='border-0 font-droid'  rows={8} value={publicationData.article} onChange={ (e,value) => setPublicationData({...publicationData, article:e.target.value})} />
                </Form>
                <div className='p-1'>
                    <Button className='rounded-pill' fluid onClick={() => SavePublicationFunc('article','article','article')} disabled={disabledSaveBtn} size='small' icon style={{backgroundColor:'#314770', color:'white'}} >   تسجيل   <Icon name='save' />   <Loader inverted active={loaderState} inline size='tiny' className='ms-2 text-danger'/></Button>
                </div>
            </div>
    </>)
}
const ImagePubCard = ({publicationData, setPublicationData, SavePublicationFunc, disabledSaveBtn , OnKeyPressFunc, loaderState}) => {
    const [imageLink, setImageLink] = useState('')
    const { t, i18n } = useTranslation();
     
    const GetImageFunction = (link) => {
        setPublicationData({...publicationData, imageUrl: link })
        checkImageURL(link, isValid => {
           if (isValid) {
             setImageLink(link)
             // setPublicationData({...publicationData, imageUrl: link })

           } else {
            toast.error(<><div><h5> URL Invalide </h5>  esseyer une autre image !  </div></>, GConf.TostInternetGonf)
            //setPublicationData({...publicationData, image: link })
           }
          });
        
    }

    function checkImageURL(url, callback) {
        const img = new Image();
        
        img.onload = function() {
          callback(true); // Image loaded successfully
        };
      
        img.onerror = function() {
          callback(false); // Image failed to load
        };
      
        img.src = url;
      }

    return(<>
            <div className='  card-body  mb-4 border-div'>
                <Form className='mb-3'>
                    <TextArea placeholder={t('appPages.publicationPage.imagesData.textPlch')} onKeyPress={event => OnKeyPressFunc(event)} maxLength={180} className='border-0 font-droid'  rows={2} value={publicationData.imageText} onChange={ (e,value) => setPublicationData({...publicationData, imageText:e.target.value})} />
                </Form>
                <small> {t('appPages.publicationPage.imagesData.smallDesc')} </small>
                <input className='text-start form-control'  fluid   placeholder={t('appPages.publicationPage.imagesData.inputPlch')}   onBlur={(e) => GetImageFunction(e.target.value)} />
                <br />
                {imageLink == '' ? <></> : <img src={imageLink} width={'100%'} height='auto' />}
                <br /> 
                <br /> 
                <div className='p-1'>
                    <Button className='rounded-pill' fluid onClick={() => SavePublicationFunc('image','image','image')} disabled={disabledSaveBtn} size='small' icon style={{backgroundColor:'#216e55', color:'white'}} >   {t('appPages.publicationPage.communSaveBtn')}   <Icon name='save' />   <Loader inverted active={loaderState} inline size='tiny' className='ms-2 text-danger'/></Button>
                </div>
            </div>
    </>)
}
const VideoPubCard = ({publicationData, setPublicationData, SavePublicationFunc, disabledSaveBtn , OnKeyPressFunc, loaderState}) => {
    const [videoLink, setVideoLink] = useState('')
    const { t, i18n } = useTranslation();
     

    const GetImageFunction = (link) => {
        //checkVideoURL(link, isValid => {
           //if (isValid) {
             //console.log(true);
             setVideoLink(link)
             setPublicationData({...publicationData, videoUrl: link })

           //} else {
            //console.log(false);
            //setPublicationData({...publicationData, image: link })
           //}
          //});
        
    }

    function checkVideoURL(url, callback) {
        const video = document.createElement('video');
  
        video.onloadedmetadata = video.oncanplay = function() {
            callback(true); // Video loaded successfully
        };

        video.onerror = function() {
            callback(false); // Video failed to load
        };

        video.src = url;
    }
    
    return(<>
            <div className='  card-body  mb-4 border-div'>
                <Form className='mb-3'>
                    <TextArea placeholder={t('appPages.publicationPage.videoData.textPlch')} onKeyPress={event => OnKeyPressFunc(event)} maxLength={180} className='border-0 font-droid' rows={2} value={publicationData.videoText} onChange={ (e,value) => setPublicationData({...publicationData, videoText:e.target.value})} />
                </Form>
                <small>{t('appPages.publicationPage.videoData.smallDesc')} </small>
                <input className='text-start form-control'  fluid   placeholder={t('appPages.publicationPage.videoData.inputPlch')}  onBlur={(e) => GetImageFunction(e.target.value)} />
                <br />
                {videoLink == '' ? 
                    <></> 
                    : 
                    <iframe
                        width="100%" height="250"
                        src={`https://www.youtube.com/embed/${videoLink}`}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                    }
                <br /> 
                <br /> 
                <div className='p-1'>
                    <Button className='rounded-pill' fluid onClick={() => SavePublicationFunc('video','video','video')} disabled={disabledSaveBtn} size='small' icon style={{backgroundColor: '#b32525' , color:'white'}} >   {t('appPages.publicationPage.communSaveBtn')}   <Icon name='save' />   <Loader inverted active={loaderState} inline size='tiny' className='ms-2 text-danger'/></Button>
                </div>
            </div>
    </>)
}

const AddCommentBox = ({SentCommentFunc, setCommentData,commentData}) =>{
    const { t, i18n } = useTranslation();
    return(<>
             <div className='row '>
                <div className='col-10 align-self-center'>
                <Form>
                    <TextArea placeholder={t('appPages.requestInfoPage.sendBox.addResponse')} value={commentData.Comment_text} className="mb-2 rounded-pill" rows='1' onChange={ (e) => setCommentData({ ...commentData, Comment_text: e.target.value})}></TextArea>
                </Form>
                </div>
                <div className='col-2 align-self-center text-end'><Button  icon='send'  className='rounded-circle mb-2' onClick={SentCommentFunc}></Button></div>
            </div>
        </>)
  }


function NotificationPage() {
    /* ###############################  Const ################################*/
    const { t, i18n } = useTranslation();
    const messagesEndRef = useRef(20)
    const [messagesList, setMessageList] = useState([])
    const [msgContent, setMesgC] = useState('')
    const [updateS, setUpdateS] = useState()
    const [loading , setLoading] = useState(false)
    let [publicationData, setPublicationData] = useState([])
    let [selectedPublication, setSelctedPublication] = useState(0)
    const [openBottomSheet, setOpenBottomSheet] = useState(false)
    const [publicationDataToUp, setPublicationDataToUp] = useState({text : '', article:'', imageText:'', imageUrl:'', videoText:'', videoUrl:''})
    const [activeIndex, setActiveIndex] = useState(0)
    const [loaderState, setLS] = useState(false)
    const [disabledSaveBtn, setDisabledBtn] = useState(false)
    const panesRes = [
        {
          menuItem: { key: 'attent',  content: <span className='text-warning'><b><span className='bi bi-hourglass-split'></span> En Attent</b></span> , className:'rounded-pill'},
          render: () => <TextPubCard OnKeyPressFunc={OnKeyPressFunc} publicationData={publicationData} setPublicationData={setPublicationData} SavePublicationFunc={SavePublicationFunc} disabledSaveBtn={disabledSaveBtn} loaderState={loaderState} />,
        },
        {
          menuItem: { key: 'vu',  content: <span className='text-warning'><b><span className='bi bi-hourglass-split'></span> En Attent</b></span> , className:'rounded-pill'},
          render: () => <ArticlePubCard OnKeyPressFunc={OnKeyPressFunc} publicationData={publicationData} setPublicationData={setPublicationData} SavePublicationFunc={SavePublicationFunc} disabledSaveBtn={disabledSaveBtn} loaderState={loaderState} />,
        },
        {
          menuItem: { key: 'accept',  content: <span className='text-success'><b><span className='bi bi-check-square-fill'></span> Accepteé</b></span> , className:'rounded-pill' },
          render: () => <ImagePubCard OnKeyPressFunc={OnKeyPressFunc} publicationData={publicationData} setPublicationData={setPublicationData} SavePublicationFunc={SavePublicationFunc} disabledSaveBtn={disabledSaveBtn} loaderState={loaderState} />,
        },
        // {
        //   menuItem: { key: 'refuse',  content: <span className='text-danger'><b><span className='bi bi-x-square-fill'></span> Refuseé</b></span>, className:'rounded-pill' },
        //   render: () => <VideoPubCard OnKeyPressFunc={OnKeyPressFunc} publicationData={publicationData} setPublicationData={setPublicationData} SavePublicationFunc={SavePublicationFunc} disabledSaveBtn={disabledSaveBtn} loaderState={loaderState} />,
        // },
         
    ]


    const [file, setFile] = useState();
    const onFileChange = (event) => {
        // Updating the state
        setFile(event.target.files[0]);
        
    };
    const onFileUploadOLD = async () => {
        // Client ID
        const clientId = "4c61b93ac10206d",
        auth = "Client-ID 4c61b93ac10206d" + clientId;
 
        // Creating an object of formData
        const formData = new FormData();
 
        // Adding our image to formData
        formData.append("image", file);
        //formData.append("image", 'https://cdn.abyedh.com/Images/system_landing/forApp/docteur.JPG');
        // Making the post request
        await fetch("https://api.imgur.com/3/image", {
            // API Endpoint
            method: "POST", // HTTP Method
            body: formData, // Data to be sent
            datatype: "json",
            headers: {
                // Setting header
                Authorization: 'Client-ID 4c61b93ac10206d',
                Accept: "application/json",
            },
        })
        // Handling success
        .then( (response) => {
                alert("image uploaded")
                console.log(response.data)
                console.log(response)
            })
        .catch(
            (err) => { alert("Failed") ; console.log(err) }
        );
    };
    const onFileUpload = async () => {
        const clientId = "4c61b93ac10206d"; // Ensure this is your actual Client ID
        const auth = `Client-ID ${clientId}`;

        const formData = new FormData();
        formData.append("image", file);

        try {
            const response = await fetch("https://api.imgur.com/3/image", {
                method: "POST",
                body: formData,
                headers: {
                    Authorization: auth,
                    Accept: "application/json",
                },
            });

            const data = await response.json();

            if (data.success) {
                alert("Image uploaded successfully");
                console.log("Image URL:", data.data.link);
                console.log("Delete Hash:", data.data.deletehash);
            } else {
                alert("Upload failed");
                console.log("Error details:", data);
            }
        } catch (err) {
            alert("Failed to upload image");
            console.log("Error:", err);
        }
    };

    /* ############################### UseEffect ################################*/
    useEffect(() => {
        axios.post(`${GConf.SharedApi}/forum`, {
            PID: GConf.PID,
            systemTag : GConf.systemTag
        })
        .then(function (response) {
            setMessageList(response.data)
            setLoading(true)
            
        })
        
    }, [updateS])

    useEffect(() => {
        axios.post(`https://api.abyedh.com/api/application/Search/Search/publications`, {
            tag: GConf.systemTag,
        })
        .then(function (response) {
            // setPublicationData(response.data)
            console.log(response.data)
            
        })
        
    }, [])

    /* ############################### Functions ################################*/
 
    const AddLikeCommentNum = (toUpdate, genre, selectID) => {
        axios.post(`https://api.abyedh.com/api/application/Search/Search/publications/update`, {
            tag: GConf.systemTag,
            P_ID: selectID,
            ToUpdate : toUpdate,
            genre: genre ,
        })
        .then(function (response) {
           alert('done')
        })
    }
    const SavePublicationFunc= (genre, columnName, data) =>{
        console.log(publicationData)
        if (genre == 'text' && (!publicationData.text || publicationData.text =='')) {toast.error("أدخل النص المطلوب !", GConf.TostErrorGonf)}
        else if (genre == 'image' && (publicationData.imageUrl == '' || publicationData.imageText == '')) {toast.error("أدخل معلومات الصورة المطلوب !", GConf.TostErrorGonf)}
        else if (genre == 'video' && (publicationData.videoUrl == '' || publicationData.videoText == '')) {toast.error("أدخل معلومات الفيديو المطلوب !", GConf.TostErrorGonf)}
        else{
            setLS(true)
            axios.post(`https://api.abyedh.com/api/application/Search/publications/ajouter`, {
                PID : GConf.PID,
                TAG : GConf.systemTag ,
                genre : genre,
                publicationData: publicationData ,
            }).then(function (response) {
                console.log(response.data)
                toast.success(<><div><h5>تم تسجيل  المنشور  بنجاح </h5>  </div></>, GConf.TostInternetGonf)
                setLS(false)
                setDisabledBtn(true)
            }).catch((error) => {
                if(error.request) {
                  toast.error(<><div><h5> لم يتم تسجيل المنشور</h5> حاول مرة أخري  </div></>, GConf.TostInternetGonf)   
                  setLS(false)
                }
            });
        } 
    }
    const OnKeyPressFunc = (e) => {
        const charCode = e.charCode || e.keyCode;
        if (!((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || (charCode >= 48 && charCode <= 57) || charCode === 42 || charCode === 32 || charCode === 47 || (charCode >= 0x0600 && charCode <= 0x06FF))) {
            e.preventDefault();
        }
    }

    /* ############################### Card ################################*/
    const SendMessage = () =>{
        if (!msgContent) {toast.error("Message Vide !", GConf.TostErrorGonf)}
        else{
            axios.post(`${GConf.SharedApi}/documentation/ajouter`, {
                PID: GConf.PID,
                msgC: msgContent,
            })
            .then(function (response) {
                if(response.data.affectedRows) {
                    setUpdateS(Math.random() * 10);
                    setMesgC('')
                    toast.success("Envoyer", GConf.TostSuucessGonf)
                }
                else{
                    toast.error('Erreur', GConf.TostSuucessGonf)
                }
            })

        }
    }
    const SentMessage = (props) => {
        return(<>
                <div className="row">
                    <div className='col-2 align-self-center text-end text-secondary'><small>{props.content.Sent_Time}</small></div>
                    <div className='col-10'>
                        <div className="d-flex ">
                            <div className="flex-grow-1 me-1">
                            <div className='text-end'><small className='text-secondary'>{new Date(props.content.Sent_Date).toLocaleDateString('en-US')}</small></div>
                                <div className='card p-2 rounded-message-s mb-3 ps-4 pe-2' style={{backgroundColor:'#f0d584'}}  >{props.content.Content}</div>
                            </div>
                            <div className="flex-shrink-0 ">
                                <img src={`https://cdn.abyedh.com/images/ads/${GConf.systemTag}.svg`} alt="." className="p-0" width="50px" height="50px"/>
                            </div>
                        </div>
                    </div>
                </div>     
            </>)
    }
    const RecivedMessage = (props) => {
        return(<>
                <div className="row">
                    <div className='col-10'>
                        <div className="d-flex ">
                                <div className="flex-shrink-0 ">
                                    <img src={`https://cdn.abyedh.com/Images/logo/main-lago.gif`} alt="." className="p-0 rounded-circle" width="38px" height="38px"/>
                                </div>
                                <div className="flex-grow-1 ms-1">
                                    <small className='text-secondary'>{props.content.Sender} ( {new Date(props.content.Sent_Date).toLocaleDateString('en-US')} )</small>
                                    <div className='card p-2 rounded-message-r  mb-3 ps-2 pe-4' style={{backgroundColor:'#8affc9'}}>{props.content.Content}</div>
                                </div>
                        </div>
                        
                    </div>
                    <div className='col-2 align-self-center text-start text-secondary'><small>{props.content.Sent_Time}</small></div>
                </div> 
                
            </>)
    }
    const MessagesDetails = () =>{

        return(
                messagesList.map( (convMsg,index) => convMsg.Sender == 'SYSTEM' ? <SentMessage key={index} content={convMsg} /> : <RecivedMessage  key={index} content={convMsg} />)
                )

    }

    const PublicationCommentsCard = () => {
        const [loadingComments, setLoadingComments] = useState(true)
        const [commentsListe, setCommentsListe] = useState([])
        const [commentData, setCommentData] = useState([])

        useEffect(() => {
            axios.post(`https://api.abyedh.com/api/application/Search/Search/publications/comments`, {
                tag: GConf.systemTag,
                P_ID:  selectedPublication,
            })
            .then(function (response) {
                console.log(response.data)
                setCommentsListe(response.data)
                setLoadingComments(false)
            })         
        }, [])

        const SentCommentFunc = () => {
            axios.post(`https://api.abyedh.com/api/application/Search/Search/publications/comments/add`, {
                tag: GConf.systemTag,
                P_ID:  selectedPublication,
                commentData : {UID : GConf.UserData.UData.UID , P_ID : selectedPublication , Comment_text : commentData.Comment_text }
            })
            .then(function (response) {
                AddLikeCommentNum('Comments_Num', '+', selectedPublication)
            })  
        }

        const CommentsViewerCard = (props) => {
            return(<>
                    <div className="d-flex mb-2 border-bottom">
                        <div className="flex-shrink-0">
                            <img src={`https://cdn.abyedh.com/images/p_pic/${props.data.PictureId}.gif`} className='rounded-circle' width='30px' alt="..." />
                        </div>
                        <div className="flex-grow-1 ms-3 w-100">
                            <div className='row  mb-0'>
                               <div className='col-6 text-start'>{props.data.Name}</div>
                               <div className='col-6 text-end'><small className='text-secondary'> {new Date (props.data.Comm_Date).toLocaleDateString()}</small></div>
                            </div>
                            <div><b>{props.data.Comment_text} </b></div>
                        </div>
                    </div>
            </>)
        }
        return(<>
            <div className='card-body mt-3'>
                {loadingComments ? <Loader active={true}/> :
                <>
                    <Comment.Group>
                        {commentsListe.map((data,index) => <CommentsViewerCard key={index} data={data} />)}
                    </Comment.Group>
                </>
                }

                {/* {GConf.UserData.Logged ? 
                <AddCommentBox SentCommentFunc={SentCommentFunc} setCommentData={setCommentData} commentData={commentData}/> : 
                <></>} */}
            </div>
        </>)
    }

    const PublicationFeatureCard = (props) =>{
        const OpentAddCommentdBottomSheet = (P_ID) => {
            setSelctedPublication(P_ID)
            setOpenBottomSheet(!openBottomSheet)
        }
        const onShare = async () => {
            if (navigator.share) {
              try {
                const result = await navigator.share({
                  title: 'abyedh.com',
                  text: 'Abyedh.com Application',
                  url: 'https://play.google.com/store/apps/details?id=com.abyedh.twa',
                });
                 
              } catch (error) {
                console.error('Error sharing:', error);
              }
            } else {
              alert('Sharing is not supported in this browser.');
            }
        };
        const TextIsRTL = (text) => {
            const rtlChars = /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/;
            return rtlChars.test(text);
        };

        const PublicationGenreCard = ({ status, postData }) => {
            const StateCard = (props) =>{ return <span className={`badge bg-${props.color}`}> {props.text} </span>}
            const statusCard = React.useCallback(() => {
              switch(status) {
                case 'text': return  <TextPostCard data={postData} Name={'Houssem'} />;  
                case 'article': return  <ArticlePostCard data={postData} Name={'Houssem'} />;  
                case 'image': return  <ImagePostCard data={postData} Name={'Houssem'} />;  
                case 'video': return  <VideoPostCard data={postData} Name={'Houssem'} />;  
    
                default:  return <>Indefinie Poste</>;    
              }
            }, [status]);
          
            return (
                <div className='card  border-div mb-4 '>
                    <div className='card-body pb-0 mb-0'>
                        <div className=' row' dir='rtl'>
                            <div className='col-12'>
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0">
                                        <img src={`https://cdn.abyedh.com/images/Search/CIcons/${GConf.systemTag}.gif`}   width='50px' height='50px'/>     
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        {/* {props.data.PidData.Name}  */}
                                        <b className='text-secondary'>{postData.Owner_Name}</b>
                                        <div><small>{postData.Pub_Time.slice(0,-3)} | {new Date(postData.Pub_Date).toLocaleDateString('fr-FR').split( '/' ).reverse( ).join( '-' )}</small></div>
                                    </div>
                                </div>
                            </div>
                             
                        </div>
                        
                    </div>
                    {statusCard()}
                    <div className='p-1'>
                        
                        <hr className='mb-1 ms-2 me-2' />
                        <Button.Group fluid className='mt-0 pt-0'>
                            <Button className='bg-white'  icon onClick={() => AddLikeCommentNum('Likes_Num', '+', postData.Pub_ID)}>  {postData.Likes_Num} <Icon name='like' /> </Button>
                            <Button  className='bg-white'  icon > {postData.Comments_Num} <Icon name='comments' onClick={() => OpentAddCommentdBottomSheet(postData.Pub_ID)} /> </Button>
                            <Button  className='bg-white'  icon onClick={() => onShare()}> <Icon name='share' /></Button>
                        </Button.Group>
                    </div>
                </div> 
            );
        }
        const TextPostCard = (props) =>{
            return(<>
                    <p className='mt-3 mb-1 ps-2 pe-3 ' dir={TextIsRTL(props.data.TextData) ? 'rtl' : 'ltr'}>
                        <ShowMoreText
                            lines={3}
                            more="Voir Plus"
                            less="Moins"
                            className="content-css"
                            anchorClass="text-primary fw-bold"
                            expanded={false}
                            width={280}
                            truncatedEndingComponent={"... "}
                        >
                            {props.data.TextData}
                        </ShowMoreText>
                        
                    </p>
            </>)
        }
        const ArticlePostCard = (props) =>{
            return(<>
                    <p className='mt-3 mb-1 ps-2 pe-3 ' dir={TextIsRTL(props.data.ArticleData) ? 'rtl' : 'ltr'}>
                        <ShowMoreText
                            lines={3}
                            more="Voir Plus"
                            less="Moins"
                            className="content-css"
                            anchorClass="text-primary fw-bold"
                            expanded={false}
                            width={280}
                            truncatedEndingComponent={"... "}
                        >
                            {props.data.ArticleData}
                        </ShowMoreText>
                    </p>
            </>)
        }
        const ImagePostCard = (props) =>{
            return(<>
            <div className='card border-0'> 
                <p className='mt-3 mb-1 ps-2 pe-3 ' dir={TextIsRTL(JSON.parse(props.data.ImageData).text) ? 'rtl' : 'ltr'}> 
                    <ShowMoreText
                        lines={3}
                        more="Voir Plus"
                        less="Moins"
                        className="content-css"
                        anchorClass="text-primary fw-bold"
                        expanded={false}
                        width={280}
                        truncatedEndingComponent={"... "}
                    >
                        {JSON.parse(props.data.ImageData).text}
                    </ShowMoreText>
                </p>
                <img src={JSON.parse(props.data.ImageData).url}  />
                 
            </div> 
            </>)
        }
        const VideoPostCard = (props) =>{
            return(<>
            <div className='card border-0'>
                <p className='mt-3 mb-1 ps-2 pe-3 ' dir={TextIsRTL(JSON.parse(props.data.VideoData).text) ? 'rtl' : 'ltr'}> 
                    <ShowMoreText
                        lines={3}
                        more="Voir Plus"
                        less="Moins"
                        className="content-css"
                        anchorClass="text-primary fw-bold"
                        expanded={false}
                        width={280}
                        truncatedEndingComponent={"... "}
                    >
                        {JSON.parse(props.data.VideoData).text} 
                    </ShowMoreText>
                </p>
                <iframe
                    width="100%" height="250"
                    src={`https://www.youtube.com/embed/${JSON.parse(props.data.VideoData).url}`}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                ></iframe>
                 
            </div> 
            </>)
        }

        return(<>
                {
                    !loading ? <></>
                    :
                    <>
                    {publicationData.length == 0 ?  <>Pas de publication</> :
                    <>
                        {
                            publicationData.map((data,index) => <PublicationGenreCard key={index} status={data.Pub_Genre} postData={data} />)
                        }
                    </>}
                </>
            }
        </>)
    }

    return (<>
        <div className='container col-12 col-lg-10 text-center '>
            <div className='row'>
                <div className='col-12 col-lg-5 '>
                    <div className='sticky-top' style={{top: 80}} >
                    <>
                    5
            <input
                name="file"
                type="file"
                onChange={onFileChange}
            />
            <button onClick={onFileUpload}>Upload</button>
        </>
        {file ? <img src={URL.createObjectURL(file)} width='100px' /> : <></>}
        

                        <CustomTabs  activeIndex={activeIndex} setActiveIndex={setActiveIndex}   />
                        <Tab menu={{ secondary: true }} activeIndex={activeIndex} panes={panesRes}  className='no-menu-tabs mt-2' />
                    </div>
                </div>
                {/* <div className='col-7 '> <div ref={messagesEndRef} /> {loading ?   <MessagesDetails /> : SKLT.CardList } </div> */}
                <div className='col-12 col-lg-7 '> {publicationData.length != 0 ? <PublicationFeatureCard /> : <></>} </div>
                
            </div>
        </div>
        <BottomSheet expandOnContentDrag open={openBottomSheet}  onDismiss={() => setOpenBottomSheet(!openBottomSheet)}  >
                <PublicationCommentsCard />
        </BottomSheet>

    </>);
}

export default NotificationPage;